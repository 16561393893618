<script>
	
	export let field;
	export let value;
	export let is_invalid = false;
	export let ref; 

	/*
	export function validate() {
		if (field.required && (!value || value.trim() === '')) {
			return `${field.label || 'This field'} is required`;
		}
		return null;
	}
	*/

</script>


<div class="field field--type-textfield" class:field--inset-label={field.inset_label} bind:this={ref} id={`field-${field.field_id}`}>

	<div class="field__label">

		<label for="{field.field_id}">{field.label}</label>

		{#if field.required}
			<span class="field__required" aria-label="Required field">*</span>
		{/if}

	</div>

	<div class="field__content">

		<select class="field__input field__input--select" id={field.field_id} bind:value={value}>
			<option value={null}>Select ></option>
			{#each field.options as option}
				<option value={option.value}>{option.label}</option>
			{/each}
		</select>	

	</div>
	
	{#if is_invalid}
		<div class="field__required-message"><strong>!</strong> {is_invalid}</div>
	{/if}

	{#if field.description}
		<div class="field__description">
			{field.description}
		</div>
	{/if}
	
</div>

<script>

	import { onMount, onDestroy, setContext, getContext } from 'svelte';


	import { SCHEDULER_CONTEXT_KEY } from '@/context.js';
	const { store, util } = getContext(SCHEDULER_CONTEXT_KEY);

	const {
		submitted_form_data,
	} = store;

	import Textfield from './Textfield.svelte';
	import Textarea from './Textarea.svelte';
	import Telephone from './Telephone.svelte';
	import Email from './Email.svelte';
	import Date from './Date.svelte';
	import ButtonGroup from './ButtonGroup.svelte';
	import AppointmentType from './AppointmentType.svelte';
	import Number from './Number.svelte';
	import HealthCardNumber from './HealthCardNumber.svelte';
	import Select from './Select.svelte';

	import Fields from './Fields.svelte';

	export let fields;
	export let errors = {};
	export let externalValues = {};


	const fieldComponents = {
		textfield: Textfield,
		textarea: Textarea,
		telephone: Telephone,
		email: Email,
		date: Date,
		buttongroup: ButtonGroup,
		appointmenttype: AppointmentType,
		number: Number,
		healthcardnumber: HealthCardNumber,
		select: Select,
	};

	let fieldRefs = {};
	let nestedFieldRefs = {};
	let fieldDomRefs = {};

	let visible_fields = []
	
	$: if ($submitted_form_data || externalValues) setVisibleFields(externalValues);

		function setVisibleFields(external = {}) {
			visible_fields = fields.map(field => ({
				field_id: field.field_id,
				isVisible: isVisible(field, $submitted_form_data, external)
			}));

			for (const field of fields) {
				if (!isVisible(field, $submitted_form_data, external)) {
					delete $submitted_form_data[field.field_id];
				}
				if (field.type === 'group' && field.fields) {
					for (const nested of field.fields) {
						if (!isVisible(nested, $submitted_form_data, external)) {
							delete $submitted_form_data[nested.field_id];
						}
					}
				}
			}
		}

		export function validate(external = {}) {
			errors = {};

			for (const field of fields) {
				if (field.type === 'group') {
					const nestedRef = nestedFieldRefs[field.field_id];
					if (nestedRef?.validate) {
						const nestedErrors = nestedRef.validate(external);
						Object.assign(errors, nestedErrors);
					}
					continue;
				}

				if (isVisible(field, $submitted_form_data, external)) {
					const ref = fieldRefs[field.field_id];
					if (ref?.validate) {
						const result = ref.validate();
						if (result) {
							errors[field.field_id] = result;
						}
					} else if (field.required) {
						const val = $submitted_form_data[field.field_id];
						if (val == null || val === '') {
							errors[field.field_id] = `${field.label || 'This field'} is required`;
						}
					}
				}
			}

			return errors;
		}

	function getNestedValue(obj, path) {
		return path?.split?.('.').reduce((acc, part) => acc?.[part], obj);
	}

	function isVisible(field, formData = $submitted_form_data, external = {}) {
		if (!field.condition) return true;

		const { field_id, external_value, operator, value } = field.condition;

		let actualValue;

		if (external_value) {
			actualValue = getNestedValue(external, external_value);
		} else if (field_id) {
			actualValue = formData?.[field_id];
		} else {
			return true;
		}

		switch (operator) {
			case 'equals':
				return actualValue === value;
			case 'not_equals':
				return actualValue !== value;
			case 'not_empty':
				return actualValue !== '' && actualValue != null;
			case 'in':
				return Array.isArray(value) && value.includes(actualValue);
			default:
				return false;
		}
	}

	

</script>

{#each fields as field (field.field_id)}
	
	{#if field.type === 'group'}
		
		<div id={`group-${field.field_id}`} class={`fields-grid fields-grid--${field.grid_columns}`}>
			<Fields
				bind:this={nestedFieldRefs[field.field_id]}
				fields={field.fields}
				{errors}
			/>
		</div>

	{:else if fieldComponents[field.type] && visible_fields.find(f => f.field_id === field.field_id)?.isVisible}
		
		<svelte:component
			this={fieldComponents[field.type]}
			bind:this={fieldRefs[field.field_id]}
			field={field}
			bind:value={$submitted_form_data[field.field_id]}
			is_invalid={errors[field.field_id]}
			ref={fieldDomRefs[field.field_id]}
		/>

	{:else if !fieldComponents[field.type]}
		<p style="color:red;">Unknown field type: {field.type}</p>
	{/if}
	
{/each}

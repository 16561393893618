<script>

	export let field;
	export let value;
	export let is_invalid = false;
	export let ref; 

	export function validate() {
		if (!value || value.trim() === '') {
			return `${field.label} is required`;
		}

		const phoneRegex = /^[0-9()\s-]+$/;
		const digitCount = (value.match(/\d/g) || []).length;

		if (!phoneRegex.test(value) || digitCount < 10) {
			return 'Valid phone number required';
		}

		return null; // no error
	}

</script>


<div class="field field--type-telephone" class:field--inset-label={field.inset_label} bind:this={ref} id={`field-${field.field_id}`}>

	<div class="field__label">
		
		<label for="{field.field_id}">{field.label}</label>

		{#if field.required}
			<span class="field__required" aria-label="Required field">*</span>
		{/if}

	</div>
	<div class="field__content">

		<input
			class="field__input field__input--telephone"
			id={field.field_id}
			type="tel"
			bind:value
			class:is-invalid={is_invalid}
		/>

	</div>
	
	{#if is_invalid}
		<div class="field__required-message"><strong>!</strong> {is_invalid}</div>
	{/if}

	{#if field.description}
		<div class="field__description">
			{field.description}
		</div>
	{/if}
	
</div>



<script>
	
	export let field;
	export let value;
	export let is_invalid = false;
	export let ref; 

</script>


<div class="field field--type-button-group" bind:this={ref} id={`field-${field.field_id}`}>

	<div class="field__label">

		<label for="{field.field_id}">{field.label}</label>

		{#if field.required}
			<span class="field__required" aria-label="Required field">*</span>
		{/if}

	</div>

	<div class="field__content">

		{#each field.options as button}
			<button type="button" class="button button--outline" class:button--inactive={value != button.value} on:click={() => {value = button.value}}>
				<span class="button__label">{button.label}</span>
			</button>
		{/each}
		
	</div>
	
	{#if is_invalid}
		<div class="field__required-message"><strong>!</strong> {is_invalid}</div>
	{/if}

	{#if field.description}
		<div class="field__description">
			{field.description}
		</div>
	{/if}

	{#if value && field.conditional_notes}
		<div style="margin-top:10px">
			{@html field.conditional_notes[value]}
		</div>
	{/if}
	
</div>

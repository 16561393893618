<script>

	export let field;
	export let value;
	export let is_invalid = false;
	export let ref; 

</script>


<div class="field field--type-textarea" class:field--inset-label={field.inset_label} bind:this={ref} id={`field-${field.field_id}`}>

	<div class="field__label">

		<label for="{field.field_id}">{field.label}</label>

		{#if field.required}
			<span class="field__required" aria-label="Required field">*</span>
		{/if}

	</div>

	<div class="field__content">

		<textarea
			class="field__input field__input--textarea"
			id={field.field_id}
			bind:value
			class:is-invalid={is_invalid}
			rows="3"
		/>

	</div>
	
	{#if is_invalid}
		<div class="field__required-message"><strong>!</strong> {is_invalid}</div>
	{/if}

	{#if field.description}
		<div class="field__description">
			{field.description}
		</div>
	{/if}
	
</div>

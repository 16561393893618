<script>
	
	import { onMount, onDestroy, setContext, getContext } from 'svelte';

	export let field;
	export let value;
	export let is_invalid = false;
	export let ref; 

	const years = Array.from({ length: new Date().getFullYear() - 1900 + 1 }, (v, i) => new Date().getFullYear() - i);
	const months = [{value: '01', label: '1 - Jan'},{value: '02', label: '2 - Feb'},{value: '03', label: '3 - Mar'},{value: '04', label: '4 - Apr'},{value: '05', label: '5 - May'},{value: '06', label: '6 - Jun'},{value: '07', label: '7 - Jul'},{value: '08', label: '8 - Aug'},{value: '09', label: '9 - Sep'},{value: '10', label: '10 - Oct'},{value: '11', label: '11 - Nov'},{value: '12', label: '12 - Dec'}]
	const days = Array.from({ length: 31 }, (v, i) => String(i + 1).padStart(2, '0'));

	export function validate() {
		if (field.required && (!value || value === '' || ! year || ! month || ! day)) {
			return `${field.label || 'This field'} is required`;
		}
		return null;
	}

	let day = null
	let month = null
	let year = null

	onMount(() => {

		if (value) {
			[year, month, day] = value.split('-');
		}
	})

	$: if (day && month && year) {
		value = year+'-'+month+'-'+day
	} 

</script>


<div class="field field--type-date" bind:this={ref} id={`field-${field.field_id}`}>

	<div class="field__label">

		<label for="{field.field_id}">{field.label}</label>

		{#if field.required}
			<span class="field__required" aria-label="Required field">*</span>
		{/if}

	</div>

	<div class="field__content">

		<select class="field__input field__input--select" id={field.field_id} class:is-invalid={is_invalid} bind:value={day}>
			<option value={null}>Day</option>
			{#each days as day}
				<option value={day.toString()}>{day}</option>
			{/each}
		</select>

		<select class="field__input field__input--select" id="{field.field_id}-month" class:is-invalid={is_invalid} bind:value={month}>
			<option value={null}>Month</option>
			{#each months as month}
				<option value={month.value.toString()}>{month.label}</option>
			{/each}
		</select>

		<select class="field__input field__input--select" id="{field.field_id}-year" class:is-invalid={is_invalid} bind:value={year}>
			<option value={null}>Year</option>
			{#each years as year}
				<option value={year.toString()}>{year}</option>
			{/each}
		</select>

	</div>
	
	{#if is_invalid}
		<div class="field__required-message"><strong>!</strong> {is_invalid}</div>
	{/if}

	{#if field.description}
		<div class="field__description">
			{field.description}
		</div>
	{/if}
	
</div>

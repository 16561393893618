<script>
	
	export let field;
	export let value;
	export let is_invalid = false;
	export let ref; 

	export function validate() {
		if (!value || value === '') {
			return `${field.label} is required`;
		}

		if (!validateHealthCardNumber(value)) {
			return 'Valid health card number required';
		}

		return null; // no error
	}

	// test for valid Luhn Number, 2808940882
	function validateHealthCardNumber(value) {
		if (!value) return false; // No value provided

		const cleaned = String(value).replace(/\s+/g, ''); // remove spaces

		if (!/^\d+$/.test(cleaned)) return false; // contains non-digit characters
		if (cleaned.length < 10 || cleaned.length > 12) return false; // wrong length

		let sum = 0;
		let shouldDouble = false;

		for (let i = cleaned.length - 1; i >= 0; i--) {
			let digit = parseInt(cleaned[i], 10);

			if (shouldDouble) {
				digit *= 2;
				if (digit > 9) digit -= 9;
			}

			sum += digit;
			shouldDouble = !shouldDouble;
		}

		return sum % 10 === 0;
	}


</script>


<div class="field field--type-health-card-number" class:field--inset-label={field.inset_label} bind:this={ref} id={`field-${field.field_id}`}>

	<div class="field__label">

		<label for="{field.field_id}">{field.label}</label>

		{#if field.required}
			<span class="field__required" aria-label="Required field">*</span>
		{/if}

	</div>

	<div class="field__content">

		<input
			class="field__input field__input--number"
			id={field.field_id}
			type="number"
			bind:value
			class:is-invalid={is_invalid}
		/>

	</div>
	
	{#if is_invalid}
		<div class="field__required-message"><strong>!</strong> {is_invalid}</div>
	{/if}

	{#if field.description}
		<div class="field__description">
			{field.description}
		</div>
	{/if}
	
</div>
